<template>
    <div id="app">
        <Nav/>
        <router-view />
        <footer class="footer  mt-4  " >
            <div class="container-fluid border-top">
                <div class="row pt-2 ">
                    <div class="col-sm-6">{{ new Date().getFullYear() }} © PrettyLinge.</div>
                    <div class="col-sm-6">

                        <div class="text-sm-right d-none d-sm-block">Design & Develop by ShellBoxes</div>

                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>


        import Nav from "@/components/navBar";
        export default {
            name: "Commun",
            components: {Nav}
        }

</script>

<style scoped>

</style>