<template>
    <header>


        <b-navbar toggleable="lg" class="w-100 m-0" type="dark" style="background-color: #7697a0">
            <b-navbar-brand href="/">Gestion Stock PrettyLinge</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">

                    <div v-for="item in items" :key="item"  >
                        <b-nav-item @click="$router.push({name:item})" v-if="isAdmin == 1">{{item}}</b-nav-item>
                    </div>
                    <b-nav-item @click="$router.push({name:'Factures'})" v-if="isAdmin ==0">Factures</b-nav-item>
                    <b-nav-item @click="$router.push({name:'Gestion De Stock'})" v-if="isAdmin ==0">Gestion De Stock</b-nav-item>


                    <b-nav-item href="#" @click="logout" ><i class="fas ml-4 fa-sign-out-alt" aria-hidden="true"></i></b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

    </header>

</template>

<script>
   import Vue from 'vue'
    export default {
        name: "navBar",
        created(){
            this.auth();
        },
        data(){
            return{
                isAdmin:0,
                items:['Accueil','Factures','Gestion De Stock','Vendeurs']
            }
        },
        methods: {
            auth(){
                this.$http
                    .get('me')
                    .then(response => {
                        this.isAdmin = response.data.user.is_admin
                        console.log(response.data.user.is_admin)
                        Vue.prototype.$is_admin = response.data.user.is_admin
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            logout(){
                this.$http
                    .get('logout')
                    .then(() => {
                        localStorage.removeItem("token")
                        delete Vue.prototype.$http.defaults.headers.common['Authorization'];
                        this.$router.push('/')

                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>